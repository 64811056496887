/* eslint-disable react/no-danger */
import { FormControl, FormControlLabel, Paper, Radio, RadioGroup } from '@material-ui/core';
import Tooltip from '@material-ui/core/Tooltip';
import { Alert } from '@material-ui/lab';
import clsx from 'clsx';
import Cookies from 'js-cookie';
import React, { useMemo } from 'react';
import { LANG_DEFAULT } from 'sysconfig';
import { DateTimeUtils } from 'utils';
import { formatCurrency } from 'utils/FormatNumber';
import { v4 as uuidv4 } from 'uuid';
import styles from './styles.module.css';

const currentDate = DateTimeUtils.getFormattedDate(new Date(), 'YYYYMMDD');
const DeliveryMethodItem = ({ item, addressSelect, totalPrice = 0, productItems = [], provinces = [], translateCheckout, locale }) => {
  const { description, code, condition = {}, subTitle, status = 'ON', mapLocationFee = {} } = item;
  const { customerDistrictCode = '0', customerProvinceCode = '0', customerWardCode = '0' } = addressSelect;
  const regionCode = provinces.find((province) => province.value === customerProvinceCode)?.regionCode || '';
  const deliveryName = useMemo(() => item[`name_${locale}`] ?? item.name ?? '', [locale])
  let disable = status !== 'ON';

  let message = '';

  const isDisableByDeliveryLocation = false;

  // info
  const isFullLocation = !!mapLocationFee['00'];
  const defaultValue = mapLocationFee['00'] || null;
  const feeValue = mapLocationFee[customerDistrictCode] || mapLocationFee[customerProvinceCode] || mapLocationFee[regionCode] || defaultValue;

  if (isDisableByDeliveryLocation && !isFullLocation) {
    message = translateCheckout('not_in_deliver_region');
    disable = true;
  }

  const label = (
    <>
      <b className={styles.fw500}>{deliveryName}</b>
      {subTitle && <>&nbsp;{subTitle}</>}
      {feeValue > 0 && (
        <>
          &nbsp;({translateCheckout('plus_more')} <i className={styles.fw500}>{formatCurrency(feeValue)})</i>
        </>
      )}
    </>
  );

  // check status
  const { maxPrice, minPrice, notSupportYYYYMMDDs = null, notSupportLocationCodes = null, tags = null } = condition;

  if (!disable && notSupportYYYYMMDDs && notSupportYYYYMMDDs.indexOf(currentDate) >= 0) {
    disable = true;
    message = translateCheckout('not_support_this_day');
  }

  if (!disable && tags && tags.length > 0 && productItems) {
    productItems.forEach((product) => {
      if (!disable) {
        disable = !(product.tags && tags.every((t) => product.tags.indexOf(t) >= 0));
      }
    });
  }

  if (!disable && maxPrice && maxPrice > 0 && totalPrice > maxPrice) {
    disable = true;
    message = translateCheckout('exceeded_allowable_value');
  }

  if (!disable && minPrice && minPrice > 0 && totalPrice < minPrice) {
    message = translateCheckout('lower_than_alloweable_value');
    disable = true;
  }

  if (
    !disable &&
    notSupportLocationCodes &&
    notSupportLocationCodes.length > 0 &&
    (notSupportLocationCodes.indexOf(regionCode) !== -1 ||
      notSupportLocationCodes.indexOf(customerProvinceCode) !== -1 ||
      notSupportLocationCodes.indexOf(customerDistrictCode) !== -1 ||
      notSupportLocationCodes.indexOf(customerWardCode) !== -1)
  ) {
    message = translateCheckout('not_support_this_area');
    disable = true;
  }

  return (
    <React.Fragment key={uuidv4()}>
      <Tooltip title={message}>
        <FormControlLabel
          value={code}
          control={<Radio classes={{ root: clsx(styles.checkbox, styles.checkbox_color) }} data-test="radio-checkout-delivery" />}
          label={label}
          disabled={disable}
        />
      </Tooltip>

      {description && (
        <Alert className={styles.checkout_description} icon={false} severity="info">
          <div
            className={styles.content}
            dangerouslySetInnerHTML={{
              __html: description,
            }}
          />
        </Alert>
      )}
    </React.Fragment>
  );
};

const DeliveryMethod = ({ handleChange, deliveryMethods, selectedValue, addressSelect, totalPrice, productItems, provinces, translateCheckout }) => {
  const selectedVal = selectedValue || deliveryMethods[0]?.code;
  const locale = Cookies.get('NEXT_LOCALE') || LANG_DEFAULT;

  return (
    <Paper className={styles.root} elevation={4}>
      <h1 className={styles.title}>{translateCheckout('shipping_method')}</h1>
      <FormControl component="fieldset">
        <RadioGroup value={selectedVal} onChange={handleChange}>
          {deliveryMethods &&
            deliveryMethods.map((item) => DeliveryMethodItem({ item, addressSelect, totalPrice, productItems, provinces, translateCheckout, locale }))}
        </RadioGroup>
      </FormControl>
    </Paper>
  );
};

export default DeliveryMethod;
