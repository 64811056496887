/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/jsx-wrap-multilines */
import { Box, Button, FormControl, FormControlLabel, FormGroup, Grid, IconButton, Modal, Paper, Radio, RadioGroup } from '@material-ui/core';
import { ArrowBackIos, ArrowForwardIos } from '@material-ui/icons';
import { Alert } from '@material-ui/lab';
import { Skeleton } from '@mui/material';
import Cookies from 'js-cookie';
import Link from 'next/link';
import React, { useMemo, useState } from 'react';
import { LANG_DEFAULT } from 'sysconfig';
import ImageFallback from 'utils/ImageFallback';
import { v4 as uuidv4 } from 'uuid';
import styles from './styles.module.css';

const PaymentMethodsTab = ({
  openTab = true,
  setOpenTab = () => { },
  translateCheckout = () => { },
  paymentMethods = [],
  selectedVal = '',
  handleChange = () => { },
  locale = 'en'
}) => {
  const [paymentMethodSelected, setPaymentMethodSelected] = useState(selectedVal);

  const handleChangePaymentMethodOption = (e) => {
    setPaymentMethodSelected(e.target.value);
  };
  const handleClose = () => {
    setOpenTab(false);
  };

  const handleSubmitPaymentMethod = () => {
    const e = { target: { value: paymentMethodSelected } };
    handleChange(e);
    setOpenTab(false);
  };

  return (
    <Modal
      open={openTab}
      handleClose={handleClose}
      fullScreen
      fullWidth
      style={{
        backgroundColor: '#fff',
        position: 'fixed',
        zIndex: '1300',
        inset: '0px',
      }}
    >
      <Grid container direction="column" className={styles.containerModal}>
        <Grid item onClick={handleClose} className={styles.headerBack}>
          <Button className={styles.iconBackButton}>
            <ArrowBackIos style={{ width: '16px', height: '16px' }} />
            <span className={styles.titleBackButton}>{translateCheckout('payment_method')}</span>
          </Button>
        </Grid>
        <Grid item className={styles.listItemsBox}>
          <Grid container direction="column" style={{ height: '100%', flexWrap: 'unset' }}>
            <div className={styles.listItems}>
              <FormControl component="fieldset" style={{ width: '100%', height: '85%', marginTop: 8 }}>
                <FormGroup className={styles.radioGroup}>
                  {paymentMethods?.length > 0 ? (
                    paymentMethods.map((item) => {
                      const paymentMethodName = item[`name_${locale}`] || item?.name || '';
                      const paymentMethodSubTitle = item[`subTitle_${locale}`] || item?.subTitle || '';
                      return (
                        <RadioGroup
                          value={paymentMethodSelected}
                          className={styles.groupCheckBox}
                          onChange={handleChangePaymentMethodOption}
                        >
                          <FormControlLabel
                            value={item.code}
                            disabled={item.isDisable}
                            control={<Radio size="small" />}
                            label={
                              <Box display="flex" gridGap={8} alignItems="center">
                                <Box display="flex" flexDirection="column" justifyContent="center" flexGrow={1} gridGap={4}>
                                  <h6 className={styles.titleContent}>{paymentMethodName}</h6>
                                  {
                                    paymentMethodSubTitle.length > 0 &&
                                    <div className={styles.subTitle}>
                                      {
                                        item?.url?.length > 0
                                          ? <a href={item.url} target="_blank" rel="noreferrer">{paymentMethodSubTitle}</a>
                                          : paymentMethodSubTitle
                                      }
                                    </div>
                                  }
                                </Box>
                                <Box display="flex" flexWrap="no-wrap" gridGap={4}>
                                  {item?.svgIcons?.length > 0 &&
                                    item.svgIcons.map((svgIconURL) => (
                                      <ImageFallback key={svgIconURL} src={svgIconURL} alt={item.name} width="40px" height="36px" />
                                    ))}
                                </Box>
                              </Box>
                            }
                          />
                        </RadioGroup>
                      )
                    })
                  ) : (
                    <>
                      <Skeleton variant="rect" width="100%" height={70} />
                      <Skeleton variant="rect" width="100%" height={70} />
                      <Skeleton variant="rect" width="100%" height={70} />
                      <Skeleton variant="rect" width="100%" height={70} />
                      <Skeleton variant="rect" width="100%" height={70} />
                      <Skeleton variant="rect" width="100%" height={70} />
                    </>
                  )}
                </FormGroup>
              </FormControl>
            </div>
            <div className={styles.listItems}>
              <div className={styles.donePaymentButtonBox}>
                <Button variant="contained" color="primary" className={styles.donePaymentButton} onClick={handleSubmitPaymentMethod}>
                  {translateCheckout('confirm')}
                </Button>
              </div>
            </div>
          </Grid>
        </Grid>
      </Grid>
    </Modal>
  );
};

const PaymentMethodItem = React.memo(({ item, isSelected, onChange, locale = 'en' }) => {
  const { code, name, subTitle, url, isDisable, svgIcons = [] } = item || {};
  const paymentMethodName = item[`name_${locale}`] || name || '';
  const paymentMethodSubTitle = item[`subTitle_${locale}`] || subTitle || '';

  return (
    <RadioGroup className={styles.fieldset} value={isSelected && code} onChange={onChange}>
      <FormControlLabel
        className={`${isSelected ? 'active_payment' : ''}`}
        value={code}
        disabled={isDisable}
        control={
          <Radio
            classes={{
              root: isSelected ? styles.checkbox_color : styles.noncheckbox_color,
            }}
            data-test="radio-checkout-payment"
            size="small"
          />
        }
        label={
          <Grid container alignItems="center" justifyContent='space-between'>
            <Grid item xs={10} container direction="column" justifyContent="center">
              <Grid item>
                <h6 className={styles.fw500}>{paymentMethodName}</h6>
              </Grid>
              {
                paymentMethodSubTitle?.length > 0 &&
                <Grid item>
                  <div className={styles.subTitle}>
                    {
                      url.length > 0
                        ? <a href={url} target="_blank" rel="noreferrer">{paymentMethodSubTitle}</a>
                        : paymentMethodSubTitle
                    }
                  </div>
                </Grid>
              }
            </Grid>
            <Grid item xs={2} className={styles.textRight}>
              {svgIcons.map((svgIconURL) => (
                <ImageFallback key={svgIconURL} src={svgIconURL} alt={paymentMethodName} width="40px" height="36px" />
              ))}
            </Grid>
          </Grid>
        }
      />
    </RadioGroup>
  );
});

const PaymentMethod = ({
  handleChange,
  selectedValue = '',
  paymentMethods = [],
  paymentMethodDefault,
  user,
  translateCheckout,
  isMobile = false,
}) => {
  const { wardCode = '', districtCode = '', provinceCode = '' } = user;
  const locale = Cookies.get('NEXT_LOCALE') || LANG_DEFAULT;
  const [openTab, setOpenTab] = useState(false);
  const selectedVal = useMemo(() => selectedValue || paymentMethodDefault?.code || '', [selectedValue, paymentMethodDefault?.code]);
  const selectedPaymentMethod = paymentMethods.find((method) => method.code === selectedVal);
  const { description, errorMessage } = selectedPaymentMethod || {};
  const checkExistDescriptionContent = useMemo(() => description && description.length > 0 && description !== '<p></p>\n', [description]);
  const selectedPaymentName = useMemo(() => {
    try {
      return selectedPaymentMethod[`name_${locale}`] || selectedPaymentMethod?.name || '';
    } catch (e) {
      return selectedPaymentMethod?.name || '';
    }
  }, [selectedPaymentMethod, locale]);
  const selectedPaymentSubTitle = useMemo(() => {
    try {
      return selectedPaymentMethod[`subTitle_${locale}`] || selectedPaymentMethod?.subTitle || '';
    } catch (e) {
      return selectedPaymentMethod?.subTitle || '';
    }
  }, [selectedPaymentMethod, locale]);

  return (
    <Paper className={styles.root} elevation={4}>
      {isMobile ? (
        <>
          <Grid container alignItems="space-between" onClick={() => setOpenTab(true)}>
            <Grid item xs={11}>
              <h1 className={styles.title}>{translateCheckout('payment_method')}</h1>
            </Grid>
            <Grid item xs={1} style={{ position: 'relative' }}>
              <IconButton style={{ padding: 0, position: 'absolute', top: 0, right: 0 }}>
                <ArrowForwardIos style={{ width: '14px', height: '14px', color: '#00000061' }} />
              </IconButton>
            </Grid>
          </Grid>
          {selectedPaymentMethod && (
            <Grid container alignItems="space-between">
              <Grid item xs={10}>
                <h6 className={styles.fw500}>{selectedPaymentName}</h6>
                {selectedPaymentSubTitle?.length > 0 && <div className={styles.subTitle}>{selectedPaymentSubTitle}</div>}
              </Grid>
              <Grid item xs={2} style={{ position: 'relative' }}>
                {selectedPaymentMethod?.svgIcons?.map((svgIconURL) => (
                  <ImageFallback key={svgIconURL} src={svgIconURL} alt={selectedPaymentName} width="40px" height="36px" />
                ))}
              </Grid>
            </Grid>
          )}
          <PaymentMethodsTab
            openTab={openTab}
            setOpenTab={setOpenTab}
            translateCheckout={translateCheckout}
            paymentMethods={paymentMethods}
            selectedVal={selectedVal}
            handleChange={handleChange}
            locale={locale}
          />
        </>
      ) : (
        <>
          <h1 className={styles.title}>{translateCheckout('payment_method')}</h1>
          <FormControl component="fieldset">
            <div className={styles.paymentMethodsGrid}>
              {paymentMethods?.length > 0 ? (
                paymentMethods.map((item) => (
                  <PaymentMethodItem
                    key={item.code}
                    item={item}
                    isSelected={selectedVal === item.code}
                    onChange={handleChange}
                    locale={locale}
                  />
                ))
              ) : (
                <>
                  <Skeleton variant="rect" width="100%" height={70} />
                  <Skeleton variant="rect" width="100%" height={70} />
                  <Skeleton variant="rect" width="100%" height={70} />
                  <Skeleton variant="rect" width="100%" height={70} />
                  <Skeleton variant="rect" width="100%" height={70} />
                  <Skeleton variant="rect" width="100%" height={70} />
                </>
              )}
            </div>

            {errorMessage && <div className={styles.subTitle}>{errorMessage}</div>}

            {!!checkExistDescriptionContent && (
              <React.Fragment key={uuidv4()}>
                <Alert className={styles.bank_info} icon={false} severity="info">
                  <Grid container direction="row" justifyContent="space-between">
                    <Grid
                      item
                      spacing={2}
                      className={styles.content}
                      dangerouslySetInnerHTML={{
                        __html: description,
                      }}
                    />
                  </Grid>
                </Alert>
              </React.Fragment>
            )}
          </FormControl>
        </>
      )}
      {(!wardCode || !districtCode || !provinceCode) && (
        <Alert severity="info" style={{ borderRadius: '8px', marginTop: '15px' }}>
          {translateCheckout('please_enter_full')} {!wardCode && translateCheckout('ward')}
          {!districtCode && `, ${translateCheckout('district')}`}
          {!provinceCode && `, ${translateCheckout('province')}`} {translateCheckout('in')}{' '}
          <b>
            <Link href="/my-account">
              <a href="/my-account" target="_blank">
                {translateCheckout('account_info')}
              </a>
            </Link>
          </b>{' '}
          {translateCheckout('to_get_discount')}
        </Alert>
      )}
    </Paper>
  );
};

export default PaymentMethod;
